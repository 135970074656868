import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Quote({key, index, ...props}) {

  const section = props.quote

  const backgroundColour = section?.backgroundColour

  return (
    <section id={section.fieldGroupName + index} className={`${backgroundColour?.colour ? `bg-${backgroundColour.colour} py-20 md:py-36` : `my-20 md:my-36`} ${backgroundColour.colour === 'navy' && `text-white`}`}>
      <div className="container">
        <FadeInWhenVisible className="relative">
          <div className="max-w-5xl mx-auto text-center">
            <Image data={section.illustration} className="w-20 md:w-36 mb-10 mx-auto" objectFit="contain" objectPosition="center" />
            <div className={`${backgroundColour?.colour === 'navy' && `py-12 md:py-20 border border-white border-opacity-10`} px-8 md:px-20  relative`}>
              {section.caption && <h6 className={`text-h6 -top-2 left-1/2 transform -translate-x-1/2 absolute ${backgroundColour?.colour && `bg-${backgroundColour.colour} px-4`}`}>{section.caption}</h6>}
              
              <div className="quote-text text-h3 mb-12">{section.quote}</div>
              <div className="text-center">
                <div className="font-bold">{section.author?.name}</div>
                <div className="">{section.author?.role}</div>
              </div>
            </div>
          </div>
          {backgroundColour?.colour !== 'navy' && (
            <>
              <StaticImage src={'../../assets/images/contact-illustration-3.png'} className="w-16 !absolute top-0 left-0" objectFit="contain" objectPosition="center" alt="CityRelay" />
              <StaticImage src={'../../assets/images/contact-illustration-2.png'} className="w-16 !absolute bottom-0 right-0" objectFit="contain" objectPosition="center" alt="CityRelay" />
            </>
          )}
        </FadeInWhenVisible>
      </div>
    </section>
  )
}
